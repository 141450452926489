
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Player extends Vue {
  $refs!: { video: any }
  @Prop({ type: String, required: true, default: '' })
  private src!: string

  @Prop({ type: Boolean, required: false, default: true })
  private autoplay!: boolean

  private videoToggle = false
  private replayIcon = false

  private playerOptions = {
    muted: false,
    language: 'en',
    autoplay: this.autoplay,
    sources: [{
      type: 'video/mp4',
      src: this.src
    }]
  }

  private onPlayerPause (): void {
    this.videoToggle = true
  }

  private isEnd (): void {
    this.replayIcon = true
    this.videoToggle = false
    this.$emit('end-video', {})
  }

  private playVideo (): void {
    this.hideIcons()
    this.$refs.video.player.play()
  }

  private hideIcons (): void {
    this.videoToggle = false
    this.replayIcon = false
  }
}
