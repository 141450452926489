


























import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Player from '@/components/common/Player.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')
const PLAYING_VIDEO_CONTINUE_BUTTON_TEXT_KEY = 'preTest.video.playing.continue_button.text'
const ENDED_VIDEO_CONTINUE_BUTTON_TEXT_KEY = 'preTest.video.ended.continue_button.text'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine,
    Player
  }
})

export default class PreTestQuestionVideo extends Vue {
  // private videoUrl = 'https://res.cloudinary.com/hey-expert/video/upload/v1623258370/BellaHealth/BH_Encoded_2/Quidel/AudioFix/02_Pre-test_Quidel_EN_iwxkvo.mp4'
  private continueButtonTextKey = PLAYING_VIDEO_CONTINUE_BUTTON_TEXT_KEY
  private showHighlight = false
  private highlightTextKey = 'preTest.video.ended.question.text'
  private handleVideoEndEvent () {
    this.continueButtonTextKey = ENDED_VIDEO_CONTINUE_BUTTON_TEXT_KEY
    this.showHighlight = true
  }

  private mounted (): void {
    this.$store.dispatch('walkthrough/resetResultSubmit')
  }

  async created (): Promise<void> {
    const user = this.$store.getters['walkthrough/userInfo']
    const ctu = localStorage.getItem('ctu') as string
    const lpTag = (window as unknown as any).lpTag
    const sdes = [
      {
        type: 'ctmrinfo',
        info: {
          customerId: this.$store.state.auth.userId,
          userName: user.fullName,
          registrationDate: {
            day: moment(user.flyingDate).format('DD'),
            month: moment(user.flyingDate).format('MM'),
            year: moment(user.flyingDate).format('YYYY')
          },
          socialId: this.$store.state.auth.userId,
          imei: ctu,
          storeNumber: user.passportNumber
        }
      },
      {
        type: 'personal',
        personal: {
          contacts: [
            { email: this.$store.state.auth.email }
          ]
        }
      }
    ]
    console.log('QVUE: SDE info updated', sdes)
    lpTag.sdes.push(sdes)
    console.log('QVUE: SDE updated successfully')
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[1]
  }
}
